<template>
	<main>
		<div class="section s7">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="list">
						<div class="item" v-for="(item,index) in media" :key="index">
							<div class="qrcode">
								<img :src="item.image" alt="">
							</div>
							<div class="tit">{{ item.title }}</div>
						</div>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
export default {
	data(){
		return {
			media:[
				{title:"联想凌拓视频号",image:require('@/assets/image/2024/q1.png')},
				{title:"数智化研究DTinsight视频号",image:require('@/assets/image/2024/q2.png')},
				{title:"大联想伙伴网",image:require('@/assets/image/2024/q3.png')},
				{title:"联想基础设施业务群视频号",image:require('@/assets/image/2024/q4.png')},
				{title:"一起联想微博",image:require('@/assets/image/2024/q5.png')},
				{title:"联想新IT微博",image:require('@/assets/image/2024/q6.png')},
				//{title:"富士磁带存储视频号",image:require('@/assets/image/2024/q7.png')},
			]
		}
	}
}
</script>
<style lang="scss" scoped>
.s7{padding-top: 60px;
	.section-wrap{text-align: center;position: relative;z-index: 2;
		.title-box{display: inline-block; width: 537px;height:134px; background: url("~@/assets/image/2024/t5.png") no-repeat top center;background-size: contain;}
		.content-box{margin-top: 60px;
			.list{display: flex;flex-wrap: wrap;justify-content: center;
				.item{width: calc((100% - 22px*4)/5); padding: 10px;background: #fff;margin-right: 22px;margin-bottom: 22px;
					.qrcode{
						img{width: 100%;object-fit: contain;display: block;}
					}
					.tit{margin-top: 10px;font-size: 16px;text-align: center;}
				}
				.item:nth-child(5n){margin-right: 0;}
				.item:nth-last-child(1){margin-right: 0;}
			}
		}
	}
}
@media screen and (max-width: 1230px) {
	.s7{padding-top: middle(60px);
		.section-wrap{
			.title-box{width: middle(537px);height:middle(134px);}
			.content-box{margin-top: middle(60px);
				.list{
					.item{width:calc((100% - 22px*4*.82)/5); margin-right: middle(22px);padding: middle(10px);margin-bottom: middle(22px);
						.tit{margin-top: middle(10px);font-size:middle(16px);}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.s7{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:67px;}
			.content-box{margin-top: 15px;
				.list{justify-content: center;
					.item{flex:none;width: calc((100% - 10px * 2)/3); margin-right: 10px;padding: 5px;margin-bottom: 10px;
						.tit{margin-top: 5px;font-size: 12px;}
					}
					.item:nth-child(5n){margin-right: 10px;}
					.item:nth-child(3n){margin-right: 0;}
				}
			}
		}
	}
}
</style>
