<template>
	<main>
		<div class="section " id="header">
			<components-header @toLogin="toLogin" @toBook="toBook"/>
		</div>
		<div class="section scroll-section" id="s1">
			<components-s1 @toBook="toBook" @toLive="toLive"/>
		</div>
		<div class="section scroll-section" id="s2">
			<components-s2/>
		</div>
		<!--		<div class="section scroll-section" id="s3" >-->
		<!--			<components-s3 ref="s3" :speaker="speaker"/>-->
		<!--		</div>-->
		<div class="section scroll-section" id="s4">
			<components-s4 :plan="plan"/>
		</div>
		<!--		<div class="section scroll-section" id="s5">-->
		<!--			<components-s5 :video="video"/>-->
		<!--		</div>-->
		<!--		<div class="section scroll-section" id="s7">
					<components-s7/>
				</div>
				<div class="section scroll-section" id="s8">
					<components-s8/>
				</div>-->
		<div class="section scroll-section" id="s6">
			<components-s6 :file="file"/>
		</div>
		<div class="section" id="footer">
			<components-footer/>
		</div>
		<div class="float-floor" :class="[is_firefox ? 'firefox' : '']">
			<div class="list">
				<div class="item" v-for="(item,index) in section" :key="index"
					 :class="[section_active === index ? 'active' : '']" @click="toHash(item)">
					<div class="tit">{{ item.title }}</div>
				</div>
				<div class="item red" @click="toBook" v-if="!user_info.name">
					<div class="tit">立即报名</div>
				</div>
			</div>
		</div>
		<!--		<div class="float-enter float-enter2" @click="toLive" v-if="user_info.name">-->
		<!--			<div class="text">敬请期待</div>-->
		<!--		</div>-->
		<div class="float-enter" v-if="!user_info.name" @click="toBook">
			<div class="text">立即报名</div>
		</div>
	</main>
</template>

<script>
import ComponentsHeader from "@/components/views/index/Header"
import ComponentsS1 from "@/components/views/index/S1"
import ComponentsS2 from "@/components/views/index/S2"
import ComponentsS3 from "@/components/views/index/S3"
import ComponentsS4 from "@/components/views/index/S4"
import ComponentsS5 from "@/components/views/index/S5"
import ComponentsS6 from "@/components/views/index/S6"
import ComponentsS7 from "@/components/views/index/S7"
import ComponentsS8 from "@/components/views/index/S8"
import ComponentsFooter from "@/components/views/index/Footer"
import {site_config, book_config} from "@/config";
import axios from "axios";
import {getUrlParams} from "@/common/url";
import jsCookie from "js-cookie";

export default {
	components: {
		ComponentsHeader,
		ComponentsS1,
		ComponentsS2,
		ComponentsS3,
		ComponentsS4,
		ComponentsS5,
		ComponentsS6,
		ComponentsS7,
		ComponentsS8,
		ComponentsFooter
	},
	data() {
		return {
			query: {},
			speaker: [],
			plan: [],
			video: [],
			file: [],

			section: [
				{title: "首页", id: "s1"},
				{title: "大会介绍", id: "s2"},
				// {title:"演讲嘉宾",id:"s3"},
				{title: "会议日程", id: "s4"},
				//{title:"产品介绍",id:"s5"},
				// {title:"直播媒体",id:"s7"},
				// {title:"合作伙伴",id:"s8"},
				{title: "白皮书下载", id: "s6"},
			],
			section_active: 0,
		}
	},
	computed: {
		is_firefox() {
			return navigator.userAgent.toLowerCase().indexOf("firefox") !== -1;
		},
		user_info() {
			return this.$store.state.views.user_info;
		}
	},
	async mounted() {
		await this.getData();
		this.listenScroll();
		await this.$onLaunched;
		//this.checkLogin();
	},
	methods: {
		checkLogin() {
			if (this.user_info && this.user_info.name) {
				this.toLive();
			}
		},
		async getData() {
			const query = this.$route.query;
			const version = getUrlParams('version')
			const host = site_config.json_host;
			let json_url;
			if (version) {
				json_url = host + '/' + version;
			} else {
				const version_url = host + '/version.json';
				const version_data = await axios.get(version_url);
				json_url = version_data?.data?.url;
			}

			const json = await axios.get(json_url);
			const {speaker, plan, video, file} = json?.data;

			this.query = query;
			this.speaker = speaker;
			this.plan = plan;
			this.video = video;
			this.file = file;

			if (this.$refs.s3) {
				this.$nextTick(() => {
					this.$refs.s3.swiperInit();
				})
			}
		},
		toLive() {
			window.location.href = "https://uao.so/pctm931243";
		},
		toBook() {
			let url = book_config.book;
			const tc = jsCookie.get('tc') || '';
			url = url.replace('{returnUrl}', encodeURIComponent(window.location.href))
			url = url.replace('{tc}', tc);
			window.location.href = url;
		},
		toLogin() {
			let url = book_config.login;
			const tc = jsCookie.get('tc') || '';
			url = url.replace('{returnUrl}', encodeURIComponent(window.location.href))
			url = url.replace('{tc}', tc)
			window.location.href = url;
		},
		toHash(item) {
			document.querySelector('#' + item.id).scrollIntoView({
				behavior: "smooth",
			});
		},
		listenScroll() {
			const toScroll = () => {
				let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if (document.documentElement.clientWidth < 1600) {
					scrollTop = scrollTop / .9;
				} else if (document.documentElement.clientWidth < 1230) {
					scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				}
				let element = document.querySelectorAll('.scroll-section');
				let number = 0;
				for (let i = 0; i < element.length; i++) {
					var offset = element[i].offsetTop;
					if (scrollTop + 50 >= offset) {
						number = i;
					}
				}
				this.section_active = number;
			}
			window.addEventListener("scroll", toScroll)
		}
	}
}
</script>

<style lang="scss" scoped>
.float-floor {position: fixed;top: 50%;left: 35px;z-index: $z-float;transform: translateY(-50%);
	.list {background: #fff;border-radius: 8px;padding: 20px 0;box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
		.item {margin: 20px 0; padding: 0 35px;line-height: 1.5;font-size: 18px;color: rgba(17, 17, 17, 1);cursor: pointer;position: relative;}
		.item.active {font-weight: bold;color: #1a3692;}
		.item.red {color: $main;}
		.item.active::after {content: "";display: block;position: absolute;left: 0;top: 0; width: 6px;height: 100%;background: #1a3692;}
	}
}
.float-enter {position: fixed;top: 50%;right: 35px;width: 104px;height: 144px;background: #fff;border-top-right-radius: 30px; display: flex;align-items: center;justify-content: center;z-index: $z-float;border-bottom: 2px solid #1a3692;cursor: pointer;box-shadow: 0 0 15px 0 rgba(25, 53, 145, .2);
	.text {font-size: 22px;font-weight: bold;color: #1a3692;background: url("~@/assets/image/2024/6.png") no-repeat bottom center;background-size: 26px;white-space: pre-line;padding: 0 25px 30px;text-align: center;}
}
.float-enter.float-enter2 {
	.text {font-size: 18px;padding: 0 30px 30px;}
}
@media screen and (max-width: 1230px) {
	.float-floor {left: middle(35px);
		.list {border-radius: middle(8px);padding: middle(20px) 0;box-shadow: 0 middle(2px) middle(14px) 0 rgba(0, 0, 0, 0.06);
			.item {margin: middle(20px) 0; padding: 0 middle(35px);font-size: middle(18px);}
			.item.active::after {width: middle(6px);}
		}
	}
	.float-enter {width: middle(104px);height: middle(144px);border-top-right-radius: middle(30px);
		.text {font-size: middle(22px);padding: 0 middle(25px) middle(30px);background-size: middle(26px);}
	}
	.float-enter.float-enter2 {
		.text {font-size: middle(18px);padding: 0 middle(30px) middle(30px);}
	}
}
@media screen and (max-width: 992px) {
	.float-floor {display: none;}
	.float-enter {width: 57px;height: 75px;border-top-right-radius: 15px;right: 15px;
		.text {font-size: 11px;padding: 0 0 15px;background-size: 13px; white-space: normal;}
	}
	.float-enter.float-enter2 {
		.text {font-size: 11px;padding: 0 14px 15px;}
	}
}
</style>


